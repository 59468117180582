import Chest from 'components/Chest';
import Uploader from 'components/Uploader';
import AccountSuspension from 'components/AccountSuspension';
import { useEffect, useState } from 'react';
import { useGetAccountQuery } from 'store/api';
import UploadButtons from 'components/treasure/UploadButtons';

export default function MyChest() {
  const { 
    data: account, 
    isSuccess: isSuccessAccount } = useGetAccountQuery("Account", { refetchOnMountOrArgChange: true });
  const [percentageUsed, setPercentageUsed] = useState(0)

  useEffect(() => {
    if (isSuccessAccount) {   
      if(account.has_subscription) return setPercentageUsed(0);  
      setPercentageUsed(account.used_seconds / account.total_seconds * 100);
    }
  }, [account?.has_subscription]);

  return (
    <div className='container flex flex-col gap-6 md:gap-10 py-8 lg:py-[60px]'>
      {percentageUsed > 90 && (
        <AccountSuspension
          percentageUsed={percentageUsed}
        />
       )}
       {/* <UploadButtons /> */}
       <Uploader />
       <Chest />
    </div>
  )
}