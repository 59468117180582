import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { format } from 'utils/helpers'; 
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function AccountSuspension({ expiration, subscription = null , percentageUsed }) {
  const { t } = useTranslation();
  const storage = percentageUsed >= 100 ? 'full' : 'low';
  const texts = {
    title: {
      full: 'Tu almacenamiento esta lleno',
      low: 'Tu almacenamiento esta casi lleno'
    },
    subtitle: {
      full: 'Administra tus archivos para liberar espacio o unite a Chest Pro y no te preocupes nunca mas por el almacenamiento.',
      low: 'Administra tus archivos para liberar espacio o unite a Chest Pro y no te preocupes nunca mas por el almacenamiento.'
    }
  }

  return (
    <div className='flex items-center justify-center'>
      <div className='flex flex-col lg:flex-row gap-6 items-center bg-brand-gold px-5 p-3 rounded-2xl w-fit max-w-[770px]'>
        <div className='flex items-center gap-4'>
          <ExclamationCircleIcon className='h-8 w-8 text-neutral-black hidden lg:block' />
          <div className='flex flex-col'>
            <span className='lg:text-lg font-bold text-neutral-black text-center lg:text-left'>
              {texts.title[storage]}  
            </span>
            <span className='text-sm text-neutral-black text-center lg:text-left'>
              {texts.subtitle[storage] }
            </span>
          </div>
        </div>
        <Link
          to='/profile/account/subscription/plan'
          className='bg-neutral-silver-600 w-full text-lg font-bold px-5 py-3 rounded-xl lg:w-56 text-center'>
          Quiero ser pro
        </Link>
      </div>
    </div>
  );
}