import AccountSuspension from 'components/AccountSuspension';
import ProfileView from 'components/profile/Profile';
import { useState, useEffect } from 'react';
import { useGetAccountQuery } from 'store/api';

export default function Profile() {
    const [percentageUsed, setPercentageUsed] = useState(0)
    const { 
      data: account, 
      isSuccess: isSuccessAccount, refetch } = useGetAccountQuery();

    useEffect(() => {
      if (isSuccessAccount) {      
        if(account.has_subscription) return setPercentageUsed(0);  
        refetch() 
        setPercentageUsed(account.used_seconds / account.total_seconds * 100);
      }
    }, [account]);

  return (
    <>  
      <div className='container flex flex-col gap-6 md:gap-10 py-8 lg:py-[40px]'>
        {percentageUsed > 90 && (
          <AccountSuspension
            percentageUsed={percentageUsed}
          />
        )}
         <ProfileView />
      </div>
    </>
  )
}