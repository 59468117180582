import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeleteSubscriptionMutation, useGetAccountQuery, useGetPlansQuery } from 'store/api';
import { useSelector } from 'react-redux';
import Breadcrumb from 'components/Breadcrumb';
import UpdateSubscription from 'components/profile/UpdateSubscription';
import { useNavigate } from 'react-router-dom';

export default function Subscription() {
  const { t, i18n } = useTranslation();
  const paths = [
    { name: t('global.profile'), link: '/profile' },
    { name: t('global.account'), link: '/profile/account' },
    { name: t('account.update_subscription') }
  ];
  const [isLoading, setIsLoading] = useState(false);
  const { data: plans } = useGetPlansQuery(undefined, { refetchOnMountOrArgChange: true });
  const [deleteSubscription, { isLoading: isLoadingDelete }] = useDeleteSubscriptionMutation();
  const { data: account, isSuccess , refetch } = useGetAccountQuery();  
  const [currentPlan, setCurrentPlan] = useState('');
  const [lang, setLang] = useState('');

  const navigate = useNavigate()
  useEffect(() => {
    const lang = i18n.language.split('-')[0];
    setLang(lang);
    if(isSuccess){
      if(account.has_subscription){
        setCurrentPlan(account.subscription?.plan);
      }
    }
  }, [plans, account, i18n.language]);

  const handleCancel = async () => {
    try {
      setIsLoading(true);
      const result = await deleteSubscription(account.subscription.id);
      if ('error' in result) {
        console.error(result);
      } else {
        setTimeout(() => {
          setIsLoading(true)
          refetch()
          navigate('/my-chest', { replace: true });
          setIsLoading(false);

          // window.location.href = '/my-chest';
        }, 5000);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className='flex flex-col gap-8 h-full pt-10 pb-10 px-3 md:px-[120px] md:py-20 '>
      <div className='flex flex-col items-center gap-5'>
        <Breadcrumb className='px-3 md:px-0' items={paths} />
        <h2 className='text-5xl'>{t('account.update_subscription')}</h2>
        <UpdateSubscription plan={currentPlan} lang={lang} account={account} handleCancel={handleCancel} isLoading={isLoading} />
      </div>
    </div>
  );
}