import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import {
  useCreateAccountMutation,
  useGetAccountQuery,

} from 'store/api';
import Button from 'components/Button';
import Input from 'components/Input';
import ErrorMessage from 'components/ErrorMessage';
import Modal from 'components/Modal';
import TermsModal from 'components/modals/TermsModal';
import MPModal from 'components/modals/RedirectMPModal';
import spinner from 'assets/images/icon-loading-claim.png';

export default function Setup() {
  const { t, i18n } = useTranslation();
  const [createUser, { isLoading: isLoadingAccount }] = useCreateAccountMutation();
  const { data: account, isLoading: isLoadingGetAccount, refetch: refetchAccount } = useGetAccountQuery({}, { refetchOnMountOrArgChange: true });



  const [mPSub, setMPSub] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const [redirect, setredirect] = useState(false)
  const [step, setStep] = useState(0);
  const [openTerms, setOpenTerms] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();

  const validate = {
    username: watch('username'),
    name: watch('name'),
    plan: watch('plan'),
    terms: watch('terms')
  }
  
  const handleFirstStep = async (data) => {;
    if (!account?.username) {
      const result = await createUser({
        type: 'artist',
        data: {      
          username: data.username,
          full_name: data.name,
          email: account?.email,
          login_method: account?.login_method,
       }
      });
      if ('error' in result) {
        console.log(result);
        return;
      }
    }    
  };

  const handleCloseRedirect = async () => {
    setredirect(false)
  }


  const stepOne = () => (
    <>
      <div className='flex flex-col items-center px-6'>
        <h2>{t('setup.title')}</h2>
      </div>
      <div className='w-full max-w-[480px]'>
        <form onSubmit={handleSubmit(handleFirstStep)}>
          <div className='flex flex-col gap-6 mb-6'>
            <Input
              value={account?.email}
              type='email'
              name='email'
              label='Email'
              disabled />
            <Input
              type='text'
              name='username'
              label='Usuario'
              required
              autoComplete="off"
              noWhiteSpace
              register={register}
              error={errors.username && 'This field is required'} />
            <Input
              type='text'
              name='name'
              label='Nombre de artista'
              required
              autoComplete="off"
              register={register}
              error={errors.name && 'This field is required'} />
          </div>
          {errors.terms && (
            <div className='flex items-center justify-end h-14'>
              <ErrorMessage show={errors.terms} message='Accept Terms and Conditions' />
            </div>
          )}
          <div className='flex items-center gap-3 mb-8'>
            <input
              type='checkbox'
              name='terms'
              id='terms-and-conditions'
              {...register('terms', { required: true })} />
            <label htmlFor='terms-and-conditions'>
              {t('setup.step_two.terms')} {' '}
              <span className='text-brand-gold hover:cursor-pointer' onClick={() => setOpenTerms(true)}>
                {t('setup.step_two.terms_link')}
              </span>
            </label>
          </div>
          <Button
            style='primary'
            type='submit'
            text='Continuar'
            disabled={!validate.username || !validate.name || !validate.terms} />
        </form>
        <Modal show={openTerms} setShow={setOpenTerms}>
          <TermsModal toggle={() => setOpenTerms(false)} />
        </Modal>
      </div>
    </>
  )



  const LoadingComponent = () => (
    <div className="loading-spinner">
      <img src={spinner} alt='' width={20} height={20} className='animate-spin' />
    </div>
  );
  return (
    <>
      <div className='flex flex-col gap-8 items-center justify-center h-full pt-10 pb-10 md:px-[120px] md:py-20 w-full'>
        {isLoadingGetAccount ? (
          <></>
        ) : (
          <>
            {account.subscription?.plan && !mPSub && <Navigate to='/my-chest' />}
            {account?.email_verified
              ? (step === 0 ? stepOne() : <LoadingComponent />)
              : (<motion.div
                initial={{ opacity: 0, y: -30 }}
                animate={{ opacity: 1, y: 0 }}
                className='flex flex-col gap-4 px-4'>
                <h1 className='text-[76px]' style={{ lineHeight: '68px' }}>{t('global.verify_email.title')}</h1>
                <div className='flex flex-col items-center text-lg mb-6'>
                  <span className='text-neutral-silver-200'>{t('global.verify_email.email_sent_to')}</span>
                  <span>{account?.email}</span>
                </div>
                <div className='text-neutral-silver-300 text-sm mb-3 text-center'>
                  {t('global.password_reset.tip')}
                </div>
                <div className='flex items-center justify-center'>
                  <a href="https://chestmusic.com/#contactanos" className='text-brand-gold h-10 md:h-auto hover:text-brand-bronze font-semibold text-lg py-1.5'>
                    {t('global.verify_email.issues')}
                  </a>

                </div>
              </motion.div>
              )
            }
            <Modal show={redirect} >
              <MPModal handleClose={handleCloseRedirect} account={account} selectedPlan={selectedPlan} setMPSub={setMPSub} ></MPModal>
            </Modal>
          </>
        )}
      </div>
    </>
  )
}
