import React, { useState } from 'react'
import pencil from 'assets/images/icon-pencil-alt.svg'
import { useGetAccountQuery, useGetChestQuery } from 'store/api';
import StorageIndicator from 'components/storage-indicator/StorageIndicator'
import Modal from 'components/Modal'
import ChangeDataModal from 'components/modals/ChangeDataModal'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { updateUserData } from 'app/auth'
import { api } from 'utils/axios';

const ProfileHead = ({ data }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [show, setShow] = useState()
    const [ input, setInput ] = useState()

    const {
      data: account = {},
    } = useGetAccountQuery();
    
    const toggle = () => {
      setShow(!show)
      setInput('');
    }

    const handleChange = (e) => {
      setInput(e.target.value)
    }

    const changeName = () => {
      if(input === '') return;
        api.patch('account/', { full_name: input }, )
        .then(({ data }) => dispatch(updateUserData(data)))
        .catch((err) => console.log('error', err))
        .finally(() => { toggle();})
    }

    const inputData = [
        {
        label: t('account.artist_name'),
        placeholder: t('global.placeholder.write_here'),
        type: 'text'
        }
    ];
  return (
    <>
        <Modal show={show} setShow={setShow}>
            <ChangeDataModal title={t('account.modals.change_name')}  toggle={toggle} onClick={changeName}
                            subtitle={t('account.modals.change_subtitle')} primaryButton={t('global.save')}
                            secondaryButton={t('global.cancel')} inputsData={inputData} handleChange={handleChange} isAvailable={input !== ''} />
        </Modal>
        <div className='flex w-full flex-col gap-y-3 md:flex-row md:justify-between md:items-center md:px-0 px-2'>
            <div className='flex items-center gap-x-4'>
                <h3 className='font-thunder-bold !text-[64px] !leading-[68px] md:!text-[76px] !font-bold'>{data?.full_name}</h3>
                <button className='p-2 flex items-center' onClick={toggle}>
                    <img src={pencil} alt='' width={24} height={24} className='md:flex hidden' />
                </button>
            </div>
            <div className='flex justify-items-start'>
              <StorageIndicator usedSpace={account?.used_seconds} 
                                totalSpace={account?.total_seconds} 
                                showInMobile={true} 
                                reverseMobile={true}
                                hasSubscription={account?.has_subscription} />   
            </div>
        </div>
    </>
  )
}

export default ProfileHead