import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useGetChestQuery, useGetAccountQuery, useGetPlansQuery } from 'store/api';
import StorageIndicator from 'components/storage-indicator/StorageIndicator';
import { classNames } from 'utils/helpers';
import prologo from 'assets/images/pro-logo.png';
import prologo_big from 'assets/images/pro-logo-big.png';

import infinity_logo from 'assets/images/icon-infite.png';
import Button from 'components/Button';
import { CheckCircleIcon } from '@heroicons/react/24/solid';


const ProItems = () => {
  return (
    <div className='flex flex-col gap-2'>
      <CheckItem variant='pro'>
        Almacenamiento seguro ilimitado.      
      </CheckItem>
      <CheckItem variant='pro'>
        Acceso ilimitado a todas las funciones
      </CheckItem>
      <CheckItem variant='pro'>
      Acceso a nuevas funciones de la BETA.
      </CheckItem>
      <CheckItem variant='pro'>
      Beneficios del club Chest. ¡Conocelos!
      </CheckItem>
    </div>
  )
}


const FreeItems = () => {
  return (
    <div className='flex flex-col gap-2'>
      <CheckItem variant='free'>
        1 hora de almacenamiento seguro
      </CheckItem>
      <CheckItem variant='free'>
        Acceso ilimitado a todas las funciones
      </CheckItem>
    </div>
  )
}

const Price = ({ price }) => {
  return (
    <div className='mt-3 flex gap-2 !font-thunder items-baseline'>
        <span className='font-thunder text-2xl uppercase'>
          ARS
        </span>
        <p className='!text-[35px]'>
          ${price}
        </p>
        <span className='!text-base'>
          por mes
        </span>
    </div>
  )
}

const FreemiumCard = ({ title, usedSpace, totalSpace, variant = 'pro', children, subscription = false, price = 0 }) => {
  
  return (
    <div className='flex  flex-col gap-6 xl:w-1/2 w-full'>
      <div className='flex justify-between w-full h-[64px]'>
        <div className='flex flex-col gap-2'> 
            <div>
                <h4 className={classNames({
                  'text-[22px] !font-archivo !font-semibold !normal-case xl:': true,
                  'text-brand-gold': variant === 'pro',
                })}>{title}</h4>
            </div>
            <Price price={price}/>
        </div>
        <div>
            {variant === 'pro' && !subscription && <img src={prologo} className='h-[72px] w-[72px]'/>}
        </div>
      </div>
      <div className='flex flex-col items-start h-16 gap-2'>
          <h5 className='text-neutral-silver-200 !text-base !font-archivo'>Almacenamiento</h5>
          {variant === 'pro' ? 
            <div className='flex gap-2 items-center'> 
              <img src={infinity_logo} className='w-12 h-12' />
              <p className='text-brand-gold text-left !text-xs'>
                Nunca más te preocupes
                <br />
                por el almacenamiento
              </p> 
            </div>
          :
            <StorageIndicator usedSpace={usedSpace} totalSpace={totalSpace} reverse size='full' upgrade={false} showInMobile hasSubscription={false} />
          }
      </div>
      {variant === 'pro' ? <ProItems /> : <FreeItems />}
      {variant === 'pro' && !subscription && (
        <Link
          to='/profile/account/subscription/plan'
          className='bg-brand-gold w-full text-lg font-bold px-5 py-3 rounded-xl text-neutral-black text-center'>
          Quiero ser pro
        </Link>      
       )}
    </div>
  )
}

const CheckItem = ({ variant = 'pro', children }) => {
  const color = variant === 'pro' ? 'text-brand-gold' : 'text-brand-uva';
  return (
    <div className='flex items-center flex-row gap-2 text-neutral-50 !text-sm text-left'>
      <CheckCircleIcon className={color + ' h-6 w-6'} />
      {children}
    </div>
  )
}


const AccountPlan = () => {
  const [price, setPrice] = useState(0);
  const {  data: account = {}, isLoading } = useGetAccountQuery();
  const { data: plan = {}} = useGetPlansQuery();
  
  useEffect(() => {
    if(account){
      if(account.has_subscription){
        let price = parseInt(account.subscription.plan.pricing_data.price)
        price = price / account.subscription.plan.billing_frequency
        setPrice(price)
      }
    } else if(plan){
      setPrice(parseInt(plan.plans.find(p => p.billing_frequency === 1).pricing_data.price))
    }

  }, [account]);

  return (
    <>
      <div className={`container-accountPlan ${account.has_subscription && 'pro-container'}`}>
        <div className='flex gap-y-8 md:gap-x-14 flex-wrap xl:flex-nowrap flex-col w-full xl:flex-row '>
          {!account?.has_subscription && (
              <FreemiumCard title={'Chest Free'} variant='free' usedSpace={account?.used_seconds} totalSpace={account?.total_seconds}  />
            )}
          <FreemiumCard title='Chest Pro' subscription={account?.has_subscription} price={price} />
          {account.has_subscription && (
              <div className='xl:w-2/4 w-full'>
                <img src={prologo_big}  />
              </div>
            )}
        </div>
      </div>
    </>
  )
}

export default AccountPlan



