import { classNames } from "utils/helpers"

export default function Tag({ children, variant = 'free'}) {
  return (
    <>
      <span className={classNames(
        {'rounded-lg px-2 md:px-3 py-1 text-sm md:text-base text-neutral-silver-200 font-archivo font-bold': true,
          'bg-neutral-silver-700': variant === 'free',
          'bg-brand-gold  !text-neutral-black': variant === 'pro',
        })}>
        {children}
      </span>
    </>
  )
}