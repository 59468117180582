import { useTranslation } from 'react-i18next';
import { BaseModal } from 'components/BaseModal';
import Button from 'components/Button';
import CardPaymentBrick from 'components/BrickPayment';


export default function RedirectMPModal({ handleClose,  account, selectedPlan, setMPSub, promoCode }) {
    const { t } = useTranslation();
  return (
    
    <BaseModal
    onClose={handleClose}
    >
      
    <CardPaymentBrick account={account} selectedPlan={selectedPlan} setMPSub={setMPSub} promoCode={promoCode} />
    <div className='w-full mt-10'>
      <Button
        text={t('global.close')}
        style='tertiary'
        onClick={handleClose} />    
    </div>
  </BaseModal>
  );
}
